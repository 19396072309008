import React from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, makeStyles, Typography, useTheme } from '@material-ui/core'
import { groupBy } from 'lodash'
import clsx from 'clsx'
import BlockContent from '@sanity/block-content-to-react'
import useSEO from '../components/Shared/useSEO'
import PlayerCard from '../components/Team/PlayerCard'
import { TeamQuery } from '../../graphql-types'
import blockContentserializer from '../components/Shared/BlockContentSerializer'
const useStyles = makeStyles(theme => ({
    heading: {
        color: theme.palette.grey[400],
        fontWeight: 700,
        textTransform: 'uppercase',
        fontStyle: 'italic',
        letterSpacing: -10,
        fontSize: 200,
        [theme.breakpoints.down('md')]: {
            fontSize: 150,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 90,
        },
    },
    darkHeading: {
        color: theme.palette.grey[600],
    },
    positionHeading: {
        position: 'absolute',
    },
    teamSection: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(6),
    },
    trainingTimes: {
        marginBottom: theme.spacing(6),
    },
    grid: {
        paddingTop: theme.spacing(12),
    },
}))

interface TeamProps {
    data: TeamQuery
}

export default ({ data }: TeamProps) => {
    const classes = useStyles()
    const grouped = groupBy(data.allSanityPlayer.nodes, p => p.position[0])
    useSEO({ title: data.sanityTeam.name })
    return (
        <>
            <Typography variant="h1" className={clsx(classes.heading, classes.darkHeading)}>
                {data.sanityTeam.name}
            </Typography>
            <Container maxWidth="lg">
                <BlockContent
                    blocks={data.sanityTeam._rawDescription}
                    serializers={blockContentserializer}
                />
            </Container>
            {Object.keys(grouped).map(key => {
                return (
                    <div key={key} className={classes.teamSection}>
                        <Typography
                            variant="h1"
                            component="div"
                            className={clsx(classes.heading, classes.positionHeading)}
                            gutterBottom
                            style={{ position: 'absolute' }}>
                            {key}
                        </Typography>
                        <Container maxWidth="lg">
                            <Grid container spacing={2} className={classes.grid}>
                                {grouped[key].map(p => (
                                    <PlayerCard key={p.id} {...(p as any)} />
                                ))}
                            </Grid>
                        </Container>
                    </div>
                )
            })}

            <Typography
                variant="h1"
                component="div"
                className={clsx(classes.heading, classes.darkHeading)}>
                {'Training'}
            </Typography>
            <Container maxWidth="lg" className={classes.trainingTimes}>
                <BlockContent
                    blocks={data.sanityTeam._rawTrainingTimes}
                    serializers={blockContentserializer}
                />
            </Container>
        </>
    )
}

export const query = graphql`
    query Team($teamId: String!) {
        sanityTeam(id: { eq: $teamId }) {
            id
            name
            _rawDescription
            _rawTrainingTimes
        }
        allSanityPlayer(filter: { team: { id: { eq: $teamId } } }, sort: { fields: name }) {
            nodes {
                id
                name
                number
                position
                description
                image {
                    ...ImageWithPreview
                }
            }
        }
    }
`
